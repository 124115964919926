// export const loadMore = () => {
// let currentPage = 1;
// const ajxUrl=ajaxUrl;
// jQuery("#load-more-news").on("click", function () {
//   currentPage++; // Do currentPage + 1, because we want to load the next page

//   jQuery.ajax({
//     type: "POST",
//     url: ajxUrl,
//     dataType: "json", // <-- Change dataType from 'html' to 'json'
//     data: {
//       action: "weichie_load_more",
//       paged: currentPage,
//     },
//     success: function (res) {
//       if (currentPage >= res.max) {
//         jQuery("#hide__button-news").hide();
//       }
//       jQuery(".blog_posts_list").append(res.html);
//     },
//   });
// });
// }

export const loadMore = () => {
   const postPermalink = () => {

    const links = document.querySelectorAll('.post-permalink');

    links.forEach(link => {
        link.addEventListener('mouseover', () => {
            let findLinks = link.closest('.post').querySelectorAll('.post-permalink');
            findLinks.forEach(findLink => {
                findLink.classList.add('active');
            });
        });
        link.addEventListener('mouseout', () => {
            let findLinks = link.closest('.post').querySelectorAll('.post-permalink');
            findLinks.forEach(findLink => {
                findLink.classList.remove('active');
            });
        });
    });

}
  const ajxUrl=ajaxUrl;
    $('#load-more-posts').each(function () {
        let btn = $(this);
        let wrapper = btn.closest('.posts').find('.posts_wrapper');
        let text = btn.text();
        let loadText = btn.attr('data-load');
        let pageID = btn.attr('data-page-id');
        let lastPage = Number(btn.attr('data-last-page'));
        let nextPage = 2;
        btn.on('click', function () {
            $.ajax({
                url: ajxUrl,
                type: "POST",
                data: {
                    'action': 'load_posts',
                    'pageID': pageID,
                    'paged': nextPage,
                },
                beforeSend: function () {
                    btn.addClass('rotate');
                    btn.text(loadText);
                },
                success: function (results) {
                    if (nextPage === lastPage) {
                        btn.closest('div').remove();
                    } else {
                        btn.removeClass('rotate');
                        btn.text(text);
                    }
                    nextPage++;
                    wrapper.append(results);
                    postPermalink();
                }
            });
        });
    });

}