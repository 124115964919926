export const slider = () => {
$(".product_results_item_box_mobile").slick({
    dots: true,
    arrows:false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1
});


}