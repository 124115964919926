jQuery(".header_burger_wrapper").on("click", function (e) {
  e.preventDefault();
  jQuery(this).toggleClass("active");
  jQuery(".nav").toggleClass("active");
});
jQuery(".home_strategy_name").on("click", function (e) {
  e.preventDefault();
  jQuery(this).toggleClass("hover");
  jQuery(".nav").toggleClass("hover");
});
jQuery(".home_strategy_name").on("click", function (e) {
  e.preventDefault();
  jQuery(this).find(".portfolio-image-text-mob").toggleClass("hidden");
});

import { slider } from "./modules/slider.js";
import { burger } from "./modules/header/burger.js";
import { contactForm } from "./modules/contact-form.js";
import { loadMore } from "./modules/loadmore.js";

document.addEventListener("DOMContentLoaded", function (event) {
  slider();
  burger();
  contactForm();
  loadMore();
});
